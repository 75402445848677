/* navigation component molecule */
nav, nav ul {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style-image: url('/assets/icons/bullet-turbo-sm.svg');
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.7s ease-out;
  color: white;
}

nav ul li:hover {
  transition: all 0.7s ease-out;
  transform: scale(1.1);
  opacity: .3;
}

nav ul li.blog-pos {
  top: 2em;
  left: 8em;
  width: 50px;
}

nav ul li.who-pos {
  top: 5em;
  left: 15em;
  width: 200px;
}

nav ul li.mission-pos {
  top: 15em;
  left: 19em;
  width: 250px;
}

nav ul li.skills-pos {
  top: 26em;
  left: 31em;
  width: 100px;
}

nav ul li.contact-pos {
  top: 23em;
  left: 5em;
  width: 250px;
}
