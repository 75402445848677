/* button component molecule */
button {
  padding: .5em;
  font-size: 1em;
  font-weight: bold;
  transition: all 0.3s ease-out;
}

button:focus {
    outline: none;
}

button:hover {
background: #ffe3a8;
transition: all 0.3s ease-in;
}

.btn-warning {
  padding: 1em;
}

.btn-success {
  padding: 1em;
}

.btn-exit {
  position: relative;
  margin-top: 2em;
  background: rgb(255,255,255);
  border-style:  solid;
  border-color: black;
  border-radius: 10px;
}
