/* The base folder holds boilerplate content. It holds the styles every page of your site should receive. */
@import "base/breakpoints.scss";
@import "base/color.scss";
@import "base/typography.scss";
@import "base/animations.scss";

/* Your macro layout files go in the layouts folder. Styles for major sections of the layout like a header or footer and styles for a grid system would belong here. */
@import "layouts/header.scss";
@import "layouts/footer.scss";
//@import "layouts/body.scss"

/* The components folder holds all your micro layout files. Your styles for buttons and navigation and similar page components. */
@import "components/buttons.scss";
@import "components/navigation.scss";
@import "components/background.scss";

/* The components folder holds all your micro layout files. Your styles for buttons and navigation and similar page components. */
@import "pages/card-pg.scss";
