/* footer layout molecule */
footer {
  position: fixed;
  bottom: 0px;
  z-index: 5;
  font-size: 1rem;
  text-align: center;
  width: 100%;
  color: rgb(255,255,234);
}
