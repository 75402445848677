#page-about, #page-contact, #page-mission, #page-skills {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: rgba(7,0,36, .7);
  padding: .5em;
  border-width: 1.5em;
  border-color: rgba(7,0,36, .3);
  border-style: solid;
  color: white;
  overflow: auto;
}

.card-content {
  padding-bottom: 4em;
}

.card-content h1 {
  text-align: center;
  color: #ffe3a8;
  font-weight: bold;
}
