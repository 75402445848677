@media(max-width: 825px) {
  body {
    background-color: white;
  }
  nav ul li.who-pos {
    top: 4em !important;
    left: 5em !important;
    width: 200px;
  }

  nav ul li.mission-pos {
    top: 6.25em !important;
    left: 5em !important;
    width: 250px;
  }

  nav ul li.skills-pos {
    top: 8.50em !important;
    left: 5em !important;
    width: 100px;
  }

  nav ul li.contact-pos {
    top: 10.75em !important;
    left: 5em !important;
    width: 250px;
  }

}

.page-wrapper {
  position: relative;
  z-index: 1;
}
