//responsive fixed background that never moves
#starry-night {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at top, #070024 1%, #000 100%); //blue: #0c1322 //purple: #070024 //pink: #180c20
  overflow: hidden;
}
