/* application font-types atoms */

/* Tangerine Bold */
@font-face {
  font-family: 'Tangerine';
  font-style: normal;
  font-weight: 400;
  src: local('Tangerine Bold'), local('Tangerine-Bold'), url('/assets/fonts/tangerine-bold.woff2') format('woff2');
}

/* Courgette Regular */
@font-face {
  font-family: 'Courgette';
  font-style: normal;
  font-weight: 400;
  src: local('Courgette Regular'), local('Courgette-Regular'), url('/assets/fonts/courgette-regular.woff2') format('woff2');
}

body {
  font-family: "Courgette", serif;
  font-size: 1.3rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Tangerine", sans-serif;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2em;
}

nav ul li a {
  font-family: "Tangerine", sans-serif;
  color: white;
  font-size: 2.4rem !important;
}
