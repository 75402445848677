/* header layout molecule */
header {
  position: relative;
  width: 0;
  height: 0;
}

header img {
  transition: all 0.8s ease-out;
  }

header a {
  display: inline-block;
  padding-left: 10px;
  }

header img:hover {
  transition: all 0.8s ease-out;
  transform: scale(.95);
  opacity: .9
}
