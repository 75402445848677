/* color base atoms */
body {
  color: white;
}

h1, h2, h3, h4, h5 {
  color: #ffe3a8;
}

a {
  text-decoration: none;
  color: grey;
}

strong {
  color: #ffe3a8;
}
